import endpoints from '@/constants/endpoints'
import axios from 'axios'

/**
 * @name 刪除分集
 */
export const deleteCollectionById = async (collectionId: number): Promise<unknown> => {
  const res = await axios({
    method: 'DELETE',
    url: `${endpoints.COLLECTION}/${collectionId}`,
  })
  return res.data
}
